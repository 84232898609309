import React, { useState, useEffect } from 'react';
import './App.css';

type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};

const Avatar = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [indexLanguage, setIndexLanguage] = useState('');

useEffect(() => {
  // Get the language from localStorage when the component mounts
  const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
  setIndexLanguage(storedLanguage);
}, []); // Empty dependency array ensures this runs once on mount


  useEffect(() => {
    const getWalletAddress = localStorage.getItem('walletAddress');
    const cachedUser = JSON.parse(localStorage.getItem('cachedUser') || '{}');

    const fetchUserData = async () => {
      try {
        if (cachedUser && cachedUser.wallet === getWalletAddress) {
          // Use cached data if the wallet address is the same
          setUser(cachedUser.userData);
        } else {
          const response = await fetch(
            `https://peaxel.me/wp-json/custom/v1/get_user_by_metadata/?value=${getWalletAddress}`
          );
          const userData = await response.json();

          if (userData && userData.length > 0) {
            setUser(userData[0]);
            // Cache the user data
            localStorage.setItem('cachedUser', JSON.stringify({ wallet: getWalletAddress, userData: userData[0] }));
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const getInitials = (name: any) => {
    if (!name) return '';

    const names = name.split(' ');
    return names
      .map((word: any) => word.charAt(0).toUpperCase())
      .join('')
      .slice(0, 2);
  };

  return (
    <div>
      {loading && <p>Loading...</p>}
      {user && (
        <span className="avatar-menu-container">
          <span className="name-menu">{user}&nbsp;</span>
          <span className="avatar-menu">{getInitials(user)}</span>
        </span>
      )}
    </div>
  );
};

export default Avatar;
